import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'

import { getAdminToken } from './utils/common'

import {
  headerCpdManage,
} from './configs/headerMenu.js'

import {
  subMenuHome,
  subMenuCpdManage,
} from './configs/subMenu'


import AdminLayout from './pages/AdminLayout'
import LoginToken from './pages/LoginToken'

//---- HOME
import Home from './pages/Home'

//---- Cpd Program
import CpdPoint from './pages/member/CpdPoint'
import CpdPointHistory from './pages/member/CpdPointHistory'
import Elearning from './pages/elearning/Elearning'
import ElearningPreInfo from './pages/elearning/ElearningPreInfo'
import ElearningInfo from './pages/elearning/ElearningInfo'
import ElearningExam from './pages/elearning/ElearningExam'
import Events from './pages/events/Events'
import EventsInfo from './pages/events/EventsInfo'
import EventCheckIn from './pages/events/EventCheckIn'
import EventCheckOut from './pages/events/EventCheckOut'
import EventPreregister from './pages/events/EventPreregister'
import EventSentEmail from './pages/events/EventSentEmail'
import EventPreregisterConfirm from './pages/events/EventPreregisterConfirm'
import EventConfirm from './pages/events/EventConfirm'
import EventPreregisterGeneral from './pages/events/EventPreregisterGeneral'
import EventCheckInGeneral from './pages/events/EventCheckInGeneral'
import EventCheckOutGeneral from './pages/events/EventCheckOutGeneral'
import SelfDeclaration from './pages/self_declaration/SelfDeclaration'
import AppealInfo from './pages/Appeal/AppealInfo'

import PageNotFound from './pages/PageNotFound'
import Maintenance from './pages/Maintenance'
import EmailPreregisSuccess from './pages/EmailPreregisSuccess'


const checkAuthenticated = () => {
  if (!getAdminToken()) {
    return redirect('/LoginToken/123')
  }
  return null
}


const router = createBrowserRouter([
  {
    path: '/',
    loader: checkAuthenticated,
    children: [
      {
        path: '/',
        element: <AdminLayout subMenu={subMenuHome} />,
        children: [
          {
            path: '/',
            element: <Navigate to="/Member/CpdPoint" />,
          },
        ],
      },
      {
        path: '/Member',
        element: <AdminLayout headerMenu={headerCpdManage} subMenu={subMenuCpdManage} />,
        children: [
          {
            index: true,
            element: <Navigate to="/Member" />,
            
          },
          {
            path: 'CpdPoint',
            children: [
              {
                index: true,
                element: <CpdPoint />,
              },
            ],
          },  
          {
            path: 'CpdPointHistory',
            children: [
              {
                index: true,
                element: <CpdPointHistory />,
              },
            ],
          },
                  
        ],
      },
      {
        path: '/Activity',
        element: <AdminLayout headerMenu={headerCpdManage} subMenu={subMenuCpdManage} />,
        children: [
          {
            index: true,
            element: <Navigate to="/Activity" />,
          },
          {
            path: 'Elearning',
            children: [
              {
                index: true,
                element: <Elearning />,
              },
            ],
          },
          {
            path: 'ElearningPreInfo/:id',
            children: [
              {
                index: true,
                element: <ElearningPreInfo />,
              },
            ],
          },
          {
            path: 'ElearningInfo/:id',
            children: [
              {
                index: true,
                element: <ElearningInfo />,
              },
            ],
          },
          {
            path: 'ElearningExam/:id',
            children: [
              {
                index: true,
                element: <ElearningExam />,
              },
            ],
          },
          {
            path: 'Events',
            children: [
              {
                index: true,
                element: <Events />,
              },
            ],
          },
          {
            path: 'EventsInfo/:id',
            children: [
              {
                index: true,
                element: <EventsInfo />,
              },
            ],
          },
          {
            path: 'SelfDeclaration',
            children: [
              {
                index: true,
                element: <SelfDeclaration />,
              },
            ],
          },
        ],
      },
      {
        path: '/Appeal',
        element: <AdminLayout headerMenu={headerCpdManage} subMenu={subMenuCpdManage} />,
        children: [
          {
            index: true,
            element: <Navigate to="/Appeal" />,
          },
          {
            path: 'AppealInfo',
            children: [
              {
                index: true,
                element: <AppealInfo />,
              },
            ],
          },                  
        ],
      },
    ],
  },
  {
    path: '/LoginToken/:token',
    element: <LoginToken />,
  },
  {
    path: '/EventCheckIn/:id',
    element: <EventCheckIn />,
  },
  {
    path: '/EventCheckOut/:id',
    element: <EventCheckOut />,
  },
  {
    path: '/EventCheckInGeneral/:id',
    element: <EventCheckInGeneral />,
  },
  {
    path: '/EventCheckOutGeneral/:id',
    element: <EventCheckOutGeneral />,
  },
  {
    path: '/EventPreregister/:id',
    element: <EventPreregister />,
  },
  {
    path: '/EventPreregisterConfirm/:id/:user_name/:user_organization_name',
    element: <EventPreregisterConfirm />,
  },
  {
    path: '/EventSentEmail/:id/:user_name/:user_organization_name',
    element: <EventSentEmail />,
  },
  {
    path: '/EventConfirm/:id',
    element: <EventConfirm />,
  },
  {
    path: '/EventPreregisterGeneral/:id',
    element: <EventPreregisterGeneral />,
  },
  {
    path: '/EmailPreregisSuccess',
    element: <EmailPreregisSuccess />,
  },
  {
    path: '/Maintenance',
    element: <Maintenance />,
    //loader: async () => redirect('https://cpdcmsv2.coe.or.th') //---- แบบ URL
  },
  {
    path: '/*',
    element: <PageNotFound />,
  },
])

const Router = () => {
  return <RouterProvider router={router} />
}

export default Router
