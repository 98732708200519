import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import logoIcon from '../assets/images/logo-icon.png'

const Div = styled.div`
  margin-bottom: 50px;
  margin-top: 60px;
  padding: 10px;
  display: flex;
  justify-content: center;

  /*width: 512px;*/

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color : #000000;
  text-align: center;

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    
    img {
      width: 220px;
      /*height: 48px;*/
    }
    
  }
  .topic{
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    color : #A91F23;
    text-align: left;
  }
  .css_margin_top_24{
    margin-top: 24px;
  }
  .text_1{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: #182230;
    text-align: left;
  }
  .text_2{
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #101828;
    text-align: left;
  }
`

function EmailPreregisSuccess() {
  const navigate = useNavigate()

  return (
    <Div>
        <div>
            {
              /*
                          <div className="img-container">
            <img src={logoIcon} alt="loco icon" />
            </div>
              */
            }


            <div className="text_1"> 
              Thank you for registration
            </div>
            <div className="topic"> 
              ขอบคุณสำหรับการลงทะเบียน กิจกรรมสภาวิศวกร
            </div>
            <div className="text_2">ท่านสามารถติดตามรายการกิจกรรมสภาวิศวกรได้ที่ https://service.coe.or.th/</div>
            <div className="css_margin_top_24"></div>
        </div>

    </Div>
  )
}

export default EmailPreregisSuccess
